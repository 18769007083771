/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Your data are stored and secured in the EU at ", React.createElement(_components.a, {
    href: "https://www.iso.org/isoiec-27001-information-security.html"
  }, "ISO 27001"), " certified data centers."), "\n", React.createElement(_components.p, null, "Signatu is built using security best practices such as the principle of least privilege."), "\n", React.createElement(_components.p, null, "So you can assume that your account and data in Signatu is safe as long as you do your part."), "\n", React.createElement(_components.p, null, "To learn what you need to do on your part, read our ", React.createElement(_components.a, {
    href: "../docs/api/v0"
  }, "Technical Docs"), "."), "\n", React.createElement(_components.p, null, "We regularly test, assess and evaluate that the security of our data processing is effective, adequate and at the level of industry security standards."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
